<template>
	<v-card width="400" height="300" class="mx-auto mt-16 py-10">
		<v-card-title class="justify-center">Logging in...</v-card-title>
		<v-card-text class="text-center" v-if="creatingToken">
			<v-progress-circular
				:size="70"
				:width="7"
				color="green"
				indeterminate
			></v-progress-circular>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getEnv } from '@/utils/env';

export default {
		computed: {
			...mapState('user', ['authToken']),
			...mapState('accounting', ['accountingLastPath']),
		},
    created() {
			const { code, realmId } = this.$route.query;
			const accessToken = this.authToken;
			this.setQuickbooksAccessToken({code, realmId, accessToken})
			.then(response => {
				this.$router.push(this.accountingLastPath+'?from=redirect');
			}).catch((error) => {
				// Redirect even if error to replay the process.
				this.$router.push(this.accountingLastPath+'?from=redirect');
			});
    },
		data() {
			return {
				response: {},
				creatingToken: true,
			}
		},
		methods: {
			...mapActions('quickbooks',['setQuickbooksAccessToken'])
		}
}
</script>

<style lang="scss">

</style>